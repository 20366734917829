@use "src/common/styles/" as *;

.Item {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 80%;
  margin-bottom: 25px;
}

.ItemNotFound {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin-bottom: 25px;
  height: 70vh;
}

.goBackRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  margin-top: 30px;
  margin-bottom: -40px;

  &:hover {
    opacity: 0.5;
  }
}

.backButton {
  color: var(--buttonColor);
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.backText {
  font-weight: $bold;
  color: var(--buttonColor);
  font-size: large;
}

.backToMenuButton {
  margin-top: 10px;
  width: 50%;
}

.mainMenuText {
  margin: 5px;
}

.errorMessage {
  text-align: center;
  width: 80%;
  font-size: large;
}

.loading {
  height: 80vh;
  display: flex;
  justify-content: center;
}

.linksContainer {
  display: none;
}

.link {
  color: var(--buttonColor);
  font-weight: $bold;
  font-size: small;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.5;
  }
}

.blackLink {
  color: var(--textColor);
  transition: unset;

  &:hover {
    opacity: unset;
  }
}

.linkSpacer {
  margin: 0 10px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.itemDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.itemImageContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.image {
  object-fit: scale-down;
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  border-radius: 10px;
}

.itemName {
  margin: 0;
  margin-top: 10px;
  font-size: medium;
}

.logoImage {
  object-fit: contain;
}

.itemPrice {
  margin: 10px 0;
  font-weight: $regular;
  color: var(--textColor);
}

.cateringText {
  margin: 15px 0;
  font-weight: $regular;
  color: var(--textColor);
  font-style: italic;
}

.itemDescription {
  margin: 0;
  padding-bottom: 10px;
  color: var(--subtextColor);
}

.quantityPriceContainer {
  width: 100%;
  border-bottom: var(--cardColor) 1px solid;
  border-top: var(--cardColor) 1px solid;
  padding: 20px 0;
  margin-bottom: 20px;
}

.quantityPriceTextContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.quantityPriceSubtext {
  margin: 0;
  color: var(--subtextColor);
}

.quantityPicker {
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 30%;
  min-width: 25%;
}

.addToCartBanner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--subBackgroundColor);
  box-sizing: border-box;
  padding: 10px 5vw;
  z-index: 10;
}

.addToCartButton {
  width: 50%;
  border-color: var(--buttonColor);

  &:disabled {
    border-color: var(--buttonDisabledColor);
  }
}

.addToCartLoading {
  width: 50%;
  display: flex;
  justify-content: center;
}

.addToCartItemName {
  width: 45%;
  font-size: medium;
}

.addToCartButtonText {
  text-wrap: nowrap;
  font-size: small;
  color: var(--buttonTextColor);

  .addToCartButton:disabled & {
    color: var(--buttonDisabledColor);
  }
}

.contactUsLink {
  text-decoration: none;
}

.contactUsButton {
  width: 100%;
  margin-top: 35px;
}

.contactUsText {
  margin: 10px;
  color: var(--buttonTextColor);
}

.optionPickerSelectedMax {
  border-color: var(--subBackgroundColor);
}

@media (width >= 875px) {
  .Item {
    width: 80%;
  }

  .errorMessage {
    text-align: center;
    width: 60%;
    font-size: xx-large;
  }

  .backToMenuButton {
    width: 25%;
  }

  .linksContainer {
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 0;
  }

  .link {
    font-size: medium;
  }

  .itemContainer {
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-top: 0;
  }

  .cateringItemContainer {
    min-height: 400px;
    border-bottom: var(--cardColor) 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .itemDetailsContainer {
    justify-content: space-between;
    width: 50%;
    min-height: 400px;
  }

  .itemImageContainer {
    width: 50%;
    justify-content: flex-end;
  }

  .image {
    width: 80%;
  }

  .itemName {
    margin: 0;
    font-size: xx-large;
  }

  .itemDescription {
    margin: 0;
  }

  .quantityPicker {
    width: 50%;
  }

  .addToCartItemName {
    font-size: xx-large;
  }

  .addToCartLoading {
    width: 35%;
  }

  .contactUsButton {
    width: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
