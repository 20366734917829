@use "src/common/styles/" as *;

.PayButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  align-self: center;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 10px;
}

.payButtonText {
  margin: 0;
  color: var(--buttonTextColor);
}

.loadingContainer {
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.errorMessage {
  display: flex;
  align-self: center;
}