@use "src/common/styles/" as *;

.AccountItem {
  border: none;
  background: none;
  text-decoration: none;
  color: var(--textColor);
  margin: 0;
  font-size: unset;
  font-weight: unset;
  text-align: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: var(--subBackgroundColor) 1px solid;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.title {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.subtitle {
  margin: 0;
  padding: 0;
  color: var(--subtextColor);
  font-weight: normal;
}
