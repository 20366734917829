@use "src/common/styles/" as *;

.PointsSelector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--subBackgroundColor);
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: var(--cardColor);
  cursor: pointer;
}

.pointsContainerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pointsText {
  margin: 0;
  margin-left: 10px;
  text-align: left;
}

.pointsSpacer {
  margin-bottom: 5px;
}

.icon {
  width: 30px;
  height: 30px;
  color: var(--buttonColor);
}
