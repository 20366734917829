@use "src/common/styles/" as *;

.Home {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
}

.coverPhotoContainer {
  position: relative;
  width: 85%;
  height: 50vh;
  margin-top: 35px;
  margin-bottom: 25px;
}

.ownerManagedCoverPhotoMargin {
  margin-bottom: 55px;
}

.coverImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.coverPhotoInfoContainer {
  position: relative;
  width: calc(100% - 40px);
  padding: 20px 50px;
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -40px;
}

.coverPhotoName {
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
  margin-bottom: 10px;
  font-size: medium;
}

.coverPhotoTagline {
  margin: 0;
  padding: 0;
  font-weight: $bold;
  margin-bottom: 20px;
  font-size: large;
}

.coverPhotoAddress {
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
  font-style: italic;
  margin-bottom: 20px;
  font-size: medium;
}

.coverPhotoButton {
  width: min-content;
  padding: 5px 20px;
  border-color: var(--buttonColor);
  color: var(--buttonTextColor);
  font-size: medium;

  h3 {
    transition: transform 0.3s;
  }

  &:hover {
    h3 {
      transform: scale(1.05);
    }
  }
}

.coverPhotoButtonText {
  margin: 0;
  padding: 0;
  text-wrap: nowrap;
  white-space: nowrap;
}

.createdBy {
  color: var(--subtextColor);
  font-weight: $semi-bold;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  align-self: flex-start;
  margin-bottom: 25px;
  margin-left: 7.5%;
  width: 90%;
}

.createdByLink {
  color: var(--buttonColor);
  cursor: pointer;
  text-decoration: underline;
}

.aboutUsSection {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.aboutUsInfoContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--backgroundColor);
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%);
  padding: 20px;
  border-radius: 20px;
}

.aboutUsTitle {
  margin: 0;
  padding: 0;
  font-size: x-large;
  margin-bottom: 10px;
  text-align: center;
}

.aboutUsText {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: left;
  background-color: var(--backgroundColor);
  padding: 15px;
}

.aboutUsButton {
  width: min-content;
  padding: 5px 20px;
  border-color: var(--buttonColor);
  color: var(--buttonTextColor);
  font-size: medium;

  h3 {
    transition: transform 0.3s;
  }

  &:hover {
    h3 {
      transform: scale(1.05);
    }
  }
}

.aboutUsButtonText {
  margin: 0;
  padding: 0;
  text-wrap: nowrap;
  white-space: nowrap;
  font-size: small;
}

.aboutUsImage {
  display: none;
}

.popularFoodSection {
  width: 90%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popularFoodTitle {
  margin: 0;
  padding: 0;
  font-size: x-large;
  margin-bottom: 50px;
  text-align: center;
}

.popularItemsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin-bottom: 20px;
}

.popularItemContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  transition: opacity 0.3s;
  width: 125px;
  margin-bottom: 30px;

  &:hover {
    opacity: 0.5;
  }
}

.popularItemImage {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 10px;
}

.popularItemName {
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
  margin-top: 10px;
  font-size: small;
}

.popularItemPrice {
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
  margin-top: 10px;
  font-size: small;
  color: var(--subtextColor);
}

.popularItemsButton {
  width: min-content;
  padding: 5px 20px;
  border-color: var(--buttonColor);
  color: var(--buttonTextColor);
  font-size: medium;

  h3 {
    transition: transform 0.3s;
  }

  &:hover {
    h3 {
      transform: scale(1.05);
    }
  }
}

.popularItemsButtonText {
  margin: 0;
  padding: 0;
  text-wrap: nowrap;
  white-space: nowrap;
  font-size: small;
}

.reviewsSection {
  margin-top: 100px;
  width: 85%;
  border-radius: 20px;
  background-color: var(--backgroundColor);
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.reviewsTitle {
  margin: 0;
  padding: 0;
  font-size: x-large;
  margin-bottom: 30px;
  text-align: center;
}

.reviewsContainer {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.reviewContainer {
  background-color: var(--backgroundColor);
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  width: 95%;
}

.authorInfoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reviewAuthorImage {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
}

.reviewAuthorName {
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
  font-size: small;
}

.starsRow {
  margin-top: 15px;
}

.star {
  margin-right: 5px;
  color: #ff9c08;
}

.reviewText {
  font-size: smaller;
}

.getInTouchSection {
  width: 90%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.getInTouchTitle {
  margin: 0;
  padding: 0;
  font-size: x-large;
  margin-bottom: 30px;
  text-align: center;
  align-self: center;
}

.getInTouchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--backgroundColor);
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%);
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 100px;
  border-radius: 20px;
}

.mapContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.getDirectionsButton {
  width: min-content;
  padding: 5px 20px;
  border-color: var(--buttonColor);
  color: var(--buttonTextColor);
  font-size: medium;
  align-self: flex-end;
  margin-top: 20px;
}

.getDirectionsButtonText {
  margin: 0;
  padding: 0;
  text-wrap: nowrap;
  white-space: nowrap;
  font-size: small;
}

.getInTouchInformationRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.getInTouchRestaurantInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
}

.getInTouchSectionTitle {
  margin: 0;
  padding: 0;
  font-weight: $extra-bold;
  font-size: medium;
  margin-bottom: 15px;
}

.getInTouchLocations {
  margin-bottom: 25px;
}

.getInTouchLocationContainer {
  margin-bottom: 25px;
}

.getInTouchLocationCity {
  margin: 0;
  padding: 0;
  font-weight: $bold;
  font-size: medium;
}

.getInTouchLocationStreet {
  margin: 0;
  padding: 0;
  font-size: medium;
  margin-top: 10px;
}

.getInTouchLocationCityStateZip {
  margin: 0;
  padding: 0;
  font-size: medium;
  margin-top: 5px;
}

.getInTouchHours {
  margin-bottom: 25px;
}

.getInTouchHoursRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 275px;
}

.getInTouchHoursDay {
  margin: 0;
  padding: 0;
  font-size: medium;
  margin-right: 10px;
}

.getInTouchHoursText {
  margin: 0;
  padding: 0;
  font-size: medium;
  white-space: pre-line;
}

.link {
  color: var(--buttonColor);
  font-weight: $semi-bold;
  transition: opacity 0.3s;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.getInTouchSocialMedias {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.socialMediaLink {
  width: 35px !important;
  height: 35px !important;
  margin: 0 5px;
  margin-bottom: 20px;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

@media (width >= 700px) {
  .coverPhotoInfoContainer {
    width: 60%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

@media (width >= 875px) {
  .Home {
    max-width: 1150px;
  }

  .createdBy {
    margin-left: 5%;
  }

  .aboutUsImage {
    display: block;
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
  }

  .aboutUsText {
    text-align: left;
    background-color: unset;
    padding-left: 0;
  }

  .aboutUsSection {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .aboutUsInfoContainer {
    align-items: flex-start;
    width: 50%;
    margin-top: 0;
    background-color: unset;
  }

  .fullWidthAboutUs {
    width: 100%;
  }

  .aboutUsTitle {
    text-align: left;
  }

  .aboutUsButton {
    align-self: flex-start;
  }

  .popularItemsContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .popularItemContainer {
    margin-bottom: 0;
    width: 200px;
  }

  .reviewContainer {
    width: 29%;
  }

  .popularItemImage {
    width: 200px;
    height: 200px;
  }

  .getInTouchTitle {
    text-align: left;
    align-self: flex-start;
  }

  .getInTouchInformationRow {
    flex-direction: row;
  }

  .getInTouchHours {
    margin-left: 50px;
    margin-bottom: 25px;
  }

  .getInTouchContact {
    margin-left: 50px;
  }

  .getInTouchLocations {
    margin-bottom: 0;
  }
}

@media (width >= 1175px) {
  .coverPhotoContainer {
    width: 100%;
  }

  .aboutUsSection {
    width: 90%;
  }

  .getInTouchSection {
    width: 100%;
  }

  .getInTouchContainer {
    flex-direction: row;
    align-items: flex-start;
  }

  .mapContainer {
    width: 250px;
    margin-bottom: 0;
  }

  .map {
    width: 250px !important;
    height: 250px !important;
  }

  .getInTouchInformationRow {
    flex-direction: row;
  }

  .getInTouchRestaurantInfo {
    margin-left: 45px;
  }
}
