@use "src/common/styles/" as *;

.OrderDetailsCartItem {
  width: 90%;
  border-bottom: 1px solid var(--subBackgroundColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }
}

.cartItemName {
  margin: 0;
  width: 50%;
}

.cartItemPrice {
  margin: 0;
  font-weight: normal;
}
