.Router {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
}

.loading {
  width: 80vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  color: var(--buttonColor);
}

.content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 160px);
}

.footerPadding {
  margin-bottom: 100px;
}
