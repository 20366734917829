.BasicModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  justify-content: space-between;
  height: 100%;
  background-color: var(--cardColor);
}

.title {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: var(--textColor);
}

.message {
  width: 80%;
  text-align: center;
  margin: 0;
  padding: 0;
  color: var(--textColor);
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.oneButton {
  justify-content: center;
}

.button {
  width: 45%;
  background-color: var(--buttonColor);
}

.buttonText {
  margin: 10px;
  color: var(--buttonTextColor);
}
