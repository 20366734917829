@use "src/common/styles/" as *;

.CategoriesSlider {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: var(--cardColor);
  z-index: 10;
  box-shadow: 0 6px 5px 0 rgb(0 0 0 / 20%);
}

.sliderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.searchContainer {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.sliderContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.searchContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.categoryName {
  margin: 0;
  color: var(--textColor);
  font-weight: $bold;
  text-align: center;
  text-wrap: nowrap;
}

.searchIcon {
  position: sticky;
  left: 0;
  background-color: var(--subBackgroundColor);
  z-index: 11;
  padding: 10px 20px;
  clip-path: inset(0 -5px 0 0);
  cursor: pointer;
  height: 100%;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.arrowLeft {
  position: sticky;
  left: 0;
  background-color: var(--subBackgroundColor);
  z-index: 11;
  padding: 10px;
  box-shadow: 0 6px 5px 0 rgb(0 0 0 / 30%);
  clip-path: inset(0 -5px 0 0);
  cursor: pointer;
  height: 100%;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.arrowRight {
  position: sticky;
  right: 0;
  margin-left: auto;
  background-color: var(--subBackgroundColor);
  z-index: 11;
  padding: 10px;
  box-shadow: 0 6px 5px 0 rgb(0 0 0 / 30%);
  clip-path: inset(0 0 0 -5px);
  cursor: pointer;
  height: 100%;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.eachSlider {
  cursor: pointer;
  padding: 10px;
  margin: 0 10px;
  transition: opacity 0.3s;

  :nth-child(1) {
    padding-left: 40px;
  }

  :last-child {
    padding-right: 20px;
  }

  &:hover {
    opacity: 0.5;
  }
}

.searchInput {
  width: 100%;
  border: none;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: medium;

  &:focus {
    outline: none;
  }
}