@use "src/common/styles/" as *;

.CartItem {
  width: 100%;
  display: flex;
  align-items: stretch;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 10px;
  height: fit-content;
  background-color: var(--cardColor);
  margin-bottom: 10px;
}

.itemImage {
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 30%;
}

.infoRows {
  width: 84%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoRowsWithoutImage {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.itemName {
  margin: 0;
  font-size: medium;
  margin-right: 10px;
}

.itemPrice {
  margin: 0;
  font-weight: normal;
  font-size: medium;
}

.optionName {
  color: var(--subtextColor);
  margin: 0;
  font-size: small;
}

.optionPrice {
  margin: 0;
  color: var(--textColor);
  font-size: small;
}

.editRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.quantityPicker {
  width: 60%;
  max-width: 150px;
  margin-right: 10px;
}

.removeButton {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: var(--buttonColor);
  font-weight: $semi-bold;
}

@media (width >= 875px) {
  .itemImage {
    max-width: 125px;
  }

  .quantityPicker {
    width: 150px;
  }
}
