@use "src/common/styles/" as *;

.Checkout {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  max-width: 1150px;
  margin-bottom: 25px;
}

.checkoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkoutPaymentContainer {
  width: 100%;
}

.orderDetails {
  width: 100% !important;
  margin: 50px 0;
}

.textInput {
  margin-bottom: 40px;
}

.textInputEmail {
  margin-bottom: 0;
}

@media (width >= 875px) {
  .Cart {
    width: 95%;
    max-width: 1150px;
  }

  .checkoutContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .checkoutPaymentContainer {
    width: 55%;
  }

  .orderDetails {
    width: 33% !important;
    margin: 0;
    margin-top: 50px;
  }
}
