@use "src/common/styles/" as *;

.promotionCheckboxContainer {
    display: flex;
    align-items: center;
    margin-top: .5rem;
}

.checkbox {
    margin-right: .5rem;
}

.promotionCheckboxText {
  font-size: small;
}

