@use "src/common/styles/" as *;

.Cart {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  margin-bottom: 25px;
}

.cartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyTitleContainer {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
}

.emptyMessage {
  text-align: center;
}

.orderNowLink {
  text-decoration: none;
  width: 100%;
}

.orderNowButton {
  width: 100%;
  border-color: var(--buttonColor);
}

.orderNowButtonText {
  margin: 5px;
  color: var(--buttonColor);
}

.cartItemsContainer {
  width: 100%;
}

.cartTitleRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.addMoreItemsButtonLink {
  text-decoration: none;
  min-width: 30%;
}

.addMoreItemsButton {
  width: 100%;
}

.addMoreItemsText {
  margin: 5px 0;
  color: var(--buttonTextColor);
}

.cartItem {
  margin-bottom: 30px;
}

.orderDetails {
  width: 100% !important;
  margin: 50px 0;
}

@media (width >= 875px) {
  .Cart {
    width: 95%;
    max-width: 1150px;
  }

  .cartContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .cartItemsContainer {
    width: 55%;
  }

  .orderDetails {
    width: 33% !important;
    margin: 0;
    margin-top: 50px;
  }
}
