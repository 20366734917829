@use "src/common/styles/" as *;

.PromoCodeInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  background-color: var(--cardColor);
}

.promoCodeApplied {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: var(--subBackgroundColor);
  border-radius: 10px;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 15%);
}

.promoCodeInfo {
  display: flex;
  flex-direction: row;
}

.promoIcon {
  color: var(--buttonColor);
  height: 20px;
  width: 20px;
}

.unapplyPromoButton {
  color: var(--textColor);
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.promoAppliedText {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-size: medium;
  font-weight: $extra-bold;
  font-style: italic;
  color: var(--buttonColor);
}

.promoCodeLabel {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-size: medium;
  font-weight: $semi-bold;
}

.inputContainer {
  display: flex;
  width: 100%;
  border: 1px solid var(--subBackgroundColor);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
}

.input {
  outline: none;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
    border: none;
  }
}

.applyButton {
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: $bold;
  color: var(--buttonColor);

  &:hover {
    opacity: 0.8;
  }
}

.disabledApply {
  cursor: not-allowed;
  opacity: 0.5;
  color: unset;

  &:hover {
    opacity: 0.5;
  }
}

.error {
  margin: 0;
  padding: 0;
  color: var(--errorColor);
  margin-top: 5px;
  font-size: small;
  font-weight: $bold;
}
