@use "src/common/styles/" as *;

.Checkbox {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.customCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: var(--buttonColor) 1px solid;
  background-color: var(--subBackgroundColor);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &.checked {
    background-color: var(--buttonColor);

    &::after {
      content: "";
      display: block;
      width: 3px;
      height: 10px;
      border: solid var(--buttonTextColor);
      border-width: 0 2px 2px 0;
      transform: rotate(40deg);
    }
  }
}

.small {
  width: 12px;
  height: 12px;

  &.checked {
    &::after {
      width: 2px;
      height: 6px;
    }
  }
}

.medium {
  width: 20px;
  height: 20px;

  &.checked {
    &::after {
      width: 3px;
      height: 10px;
    }
  }
}

.large {
  width: 28px;
  height: 28px;

  &.checked {
    &::after {
      width: 4px;
      height: 12px;
    }
  }
}


.checkboxContainer {
  position: relative;

  &.disabled {
    .customCheckbox {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.smallContainer {
  width: 15px;
  height: 15px;
}

.mediumContainer {
  width: 22px;
  height: 22px;
}

.largeContainer {
  width: 30px;
  height: 30px;
}


