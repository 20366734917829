@use "src/common/styles/" as *;

.Button {
  background-color: var(--buttonColor);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: var(--buttonTextColor);
  border: 2px solid var(--buttonColor);
  font-family: var(--textFont);
  transition: opacity 0.3s;

  &:disabled {
    background-color: var(--subBackgroundColor);
    border: 2px solid var(--textColor);
    opacity: 0.4;
    cursor: not-allowed;

    & > * {
      color: var(--textColor);
    }
  }

  &:not(:disabled):hover {
    opacity: 0.8;
  }
}

.secondary {
  background-color: var(--backgroundColor);
  color: var(--textColor);
  transition: opacity 0.3s;

  &:disabled > * {
    color: var(--textColor);
  }

  &:not(:disabled):hover {
    opacity: 0.8;
  }
}
