@use "src/common/styles/" as *;

.CartUpsell {
  padding-top: 20px;
}

.upsellTitle {
  font-size: 18px;
  font-weight: $semi-bold;
  color: var(--subtextColor);
  margin-bottom: 10px;
}

.upsellItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upsellItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.upsellNameAndPriceContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.upsellItemName {
  font-weight: bold;
  font-size: large;
}

.upsellItemPrice {
  font-size: 15px;
  color:  var(--subtextColor);
}

.upsellAddButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.upsellAddButton {
  padding: 7px;
  width: 40%;
}

.upsellAddButtonText {
  margin: 0;
  padding: 0;
}

@media (width >= 875px) {
  .upsellItem {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .upsellLeftSide {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .upsellNameAndPriceContainer {
    gap: 10px;
  }

  .quantityPickerContainer {
    display: flex;
    align-items: center;
  }

  .upsellAddButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
  }

  .upsellAddButton {
    width: 25%;
  }
}