@use "src/common/styles/" as *;

.ItemPicker {
  width: 100%;
}

.categoryName {
  margin: 0;
}

.itemNameReqContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.requiredContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--buttonColor);
  border-radius: 20px;
  padding: 5px 15px;
}

.requiredText {
  color: var(--buttonTextColor);
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
}

.categoryButton {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: var(--subBackgroundColor);
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
}

.disabled {
  opacity: 0.5;
}

.itemText {
  color: var(--textColor);
  margin: 0;
  padding: 0;
}

.angleRightIcon {
  color: var(--textColor);
  height: 20px;
}

.modalTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: var(--subBackgroundColor) 1px solid;
  background-color: var(--backgroundColor);
}

.itemsContainer {
  overflow: scroll;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 100px;
  background-color: var(--backgroundColor);
}

.itemButton {
  cursor: pointer;
  align-self: center;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: var(--subBackgroundColor);
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 15px;
}

.itemLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectCircleOuter {
  width: 22px;
  height: 22px;
  border-radius: 12px;
  border: var(--buttonColor) 2px solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background-color: var(--backgroundColor);
}

.selectCircleInner {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--buttonColor);
}

.modalItemText {
  margin: 0;
  padding: 0;
  color: var(--textColor);
}

.modalButtonsContainer {
  background-color: var(--cardColor);
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: var(--subBackgroundColor) 1px solid;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 10px 5%;
}

.modalButton {
  width: 48%;
}

.modalButtonTextSave {
  margin: 0;
  padding: 10px 0;
  color: var(--buttonTextColor);
}

.modalButtonTextCancel {
  margin: 0;
  padding: 10px 0;
  color: var(--buttonColor);
}
