@use "src/common/styles/" as *;

.PaymentDetails {
  width: 90%;
  align-self: center;
}

.paymentTypeRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.paymentTypeText {
  padding: 0;
  font-weight: $semi-bold;
  margin: 0 10px;
}

.paymentTypeDescription {
  margin: 0;
  padding: 0;
  color: var(--subtextColor);
  margin-top: 10px;
  font-weight: $semi-bold;
}

.paymentTypeSelected {
  margin-bottom: 25px;
}

.cardPaymentTypeSelected {
  margin-top: 35px;
}

.cardPaymentTypeUnselected {
  margin-top: 15px;
}
