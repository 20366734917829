@use "src/common/styles/" as *;

.OptionPicker {
  margin-top: 10px;
  margin-bottom: 20px;
}

.optionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 5px;
}

.optionName {
  margin: 0;
}

.requiredLabel,
.optionalLabel {
  padding: 3px 10px;
  border-radius: 15px;
  font-weight: $semi-bold;
}

.requiredLabel {
  background-color: var(--errorColor);
  color: var(--buttonTextColor);
}

.optionalLabel {
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
}

.minMaxText {
  margin-bottom: 20px;
  color: var(--subtextColor);
}

.optionValuesContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.optionValue {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--subBackgroundColor);
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.nonEditable {
  cursor: unset;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.optionValueName {
  margin-left: 10px;
}

.optionValuePrice {
  color: var(--subtextColor);
  font-weight: $semi-bold;
}

.selectCircleOuter {
  width: 22px;
  height: 22px;
  border-radius: 12px;
  border: var(--buttonColor) 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--subBackgroundColor);
}

.selectCircleInner {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--buttonColor);
}
