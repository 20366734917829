.OrderDetailsOptions {
  background-color: var(--buttonColor);
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  border-color: var(--buttonColor);
}

.title {
  color: var(--buttonTextColor);
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.orderDetailOptionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.icon {
  color: var(--buttonTextColor);
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.addressPredictions {
  width: 90%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: var(--subBackgroundColor) 2px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: none;
  padding: 10px 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  background-color: var(--cardColor);
}

.orderDetailsModalButton {
  background-color: transparent;
  border: none;
  color: var(--buttonTextColor);
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  flex: 1;
}

.eventDetailsModalContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.eventDetailsModal {
  background-color: var(--cardColor);
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;

}

.modalTitle {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.inputContainer {
  margin-bottom: 20px;
  width: 100%;
}

.datePickerContainer {
  width: 100%;
  margin-bottom: 20px;
}

.addressPredictionButton {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  background-color: var(--cardColor);
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-bottom: var(--subBackgroundColor) 1px solid;

  &:hover {
    background-color: var(--subBackgroundColor);
  }

  &:last-child {
    border-bottom: none;
  }
}

.addressPredictionText {
  margin: 0;
  padding: 0;
  color: var(--textColor) !important;
  font-size: 14px;
}

.noBorderRadius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-datepicker {
  font-family: inherit;
  border-radius: 10px;
  padding: 15px;
  background-color: var(--cardColor);
  border: 1px solid var(--subtextColor);
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}

.react-datepicker__header {
  background-color: var(--cardColor);
  border-bottom: none;
}

.react-datepicker__day {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.2rem;
  border-radius: 8px;
  color: var(--textColor);
  cursor: pointer;
}

.react-datepicker__day--selected,
.react-datepicker__day:hover {
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
}

.react-datepicker__time-list {
  padding: 0;
  margin: 10px 0 0;
  height: auto;
  width: 100px;
  overflow: hidden;
  border-left: 1px solid var(--subtextColor);
}

.react-datepicker__time-container {
  width: 100%;
}

.react-datepicker__time-box {
  width: 100px;
  padding: 10px 0;
}

.react-datepicker__time-list-item {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.buttonTextSave{
  color: var(--buttonTextColor);
}

.react-datepicker__time-list-item--selected,
.react-datepicker__time-list-item:hover {
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
  border-radius: 5px;
}

.datePickerInput {
  width: 100%;
  margin-bottom: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cancelButton,
.saveButton {
  width: 48%;
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
