@use "src/common/styles/" as *;

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  border-bottom: var(--subBackgroundColor) 1px solid;
  align-self: center;
  box-sizing: border-box;
}

.HeaderLeft {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
}

.HeaderCenter {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.HeaderRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 33%;
  margin-right: 20px;
}

.banner {
  background-color: var(--buttonColor);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerText {
  color: var(--buttonTextColor);
  font-weight: $bold;
  text-align: center;
}

.accountLink {
  display: none;
}

.bars {
  cursor: pointer;
  color: var(--buttonColor);
}

.drawerLogo {
  width: 50%;
  align-self: center;
  margin-bottom: 30px;
}

.drawerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--backgroundColor);
  padding: 20px 0;
}

.drawerItem {
  text-decoration: none;
  color: var(--textColor);
  font-family: var(--textFont);

  h3 {
    margin: 0;
    margin-bottom: 25px;
  }
}

.drawerItemActive {
  color: var(--buttonColor);
  text-decoration: underline;
  
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 80%;
  box-sizing: border-box;
}

.restaurantLogo {
  object-fit: contain;
  width: 100%;
  max-height: 80%;
}

.icon {
  color: var(--buttonColor);
  width: 36px;
  height: 36px;
  margin-left: 10px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.iconLink {
  text-decoration: none;
}

.checkoutButton {
  background-color: var(--buttonColor);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  text-wrap: nowrap;
  margin-left: 10px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.checkoutText {
  margin: 0;
  padding: 0;
  color: var(--buttonTextColor);
  font-weight: $semi-bold;
  text-align: center;
  font-size: small;
}

.headerItems {
  display: none;
}

@media (width >= 875px) {
  .HeaderLeft {
    display: none;
  }

  .HeaderCenter {
    margin: 0;
    width: 50%;
    justify-content: flex-start;
  }

  .Header {
    padding: 0 20px;
    max-width: 1150px;
  }

  .accountLink {
    display: block;
  }

  .headerItems {
    display: flex;
    margin-left: 20px;
  }

  .HeaderRight {
    width: 35%;
    margin: 0;
  }

  .logoContainer {
    width: 20%;
    padding-right: 0;
    box-sizing: unset;
  }

  .icon {
    margin-left: 0 10px;
  }

  .headerItem {
    text-decoration: none;
    color: var(--textColor);
    margin: 0% 20%;
    font-family: var(--textFont);
  }

  .headerItemActive {
    color: var(--buttonColor);
    text-decoration: underline;
  }

  .checkoutText {
    font-size: medium;
    color: var(--buttonTextColor);
  }
}
