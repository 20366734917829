@use "src/common/styles/" as *;

.Card {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  text-decoration: none;
  color: var(--textColor);
  background-color: var(--cardColor);
  min-height: 150px;
  width: 100%;
  position: relative;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.image {
  border-radius: 0 5px 5px 0;
  object-fit: cover;
  height: 100%;
  width: 40%;
  max-height: 200px;
}

.textContainer {
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.title {
  margin: 5px 0;
}

.justTitle {
  font-size: larger;
  color: var(--textColor);
  font-weight: $bold;
}

.description {
  margin: 5px 0;
  color: var(--subtextColor);
  font-size: small;
}

.price {
  margin: 5px 0;
  color: var(--textColor);
  font-weight: $bold;
}

.addIndicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  background-color: var(--buttonColor);
  border-radius: 20px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 45%);
}

.addIndicatorWithImage {
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 50%);
}

.addText {
  font-weight: $bold;
  margin: 0;
  padding: 0;
  color: var(--buttonTextColor);
  text-align: center;
}

@media (width >= 875px) {
  .image {
    width: 33%;
  }
}
