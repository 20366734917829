@use "src/common/styles/" as *;

.QuantityPicker {
  width: 100%;
  background-color: var(--cardColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  padding: 10px 0;
  box-sizing: border-box;
  color: var(--textColor);
}

.border {
  border: solid 1px var(--textColor);
}

.text {
  margin: 0;
}

.quantityButton {
  background: none;
  border: none;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--textColor);
}

.buttonRight {
  justify-content: flex-end;
}

.buttonRightPadding {
  padding-right: 20px;
}

.buttonLeft {
  justify-content: flex-start;
}

.buttonLeftPadding {
  padding-left: 20px;
}
