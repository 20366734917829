@use "src/common/styles/" as *;

.overflowContainer {
  overflow-x: clip;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.Menu {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  max-width: 935px;
  margin-bottom: 25px;
  position: relative;
}

.welcomeCustomer {
  margin: 0;
}

.toggleAndDeliveryContainer {
  display: flex;
  align-items: center;
}

.deliveryTime {
  margin-left: 10px;
  font-size: small;
  color: var(--textColor);
}

.titleHeader {
  margin: 0;
  margin-bottom: 0;
  margin-top: 30px;
}

.sectionHeader {
  margin-bottom: 30px 0;
}

.customerPointsContainer {
  width: 100%;
  margin-top: 20px;
}

.customerPointsTextRow {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.customerPointsText {
  margin: 0;
  margin-right: 10px;
  font-weight: $bold;
}

.pointsBar {
  width: 100%;
}

.circleInfoIcon {
  color: var(--buttonColor);
}

.itemsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px 20px;
  width: 100%;
}

.categoryContainer {
  scroll-margin-top: 100px;
  margin-bottom: 50px;
}

.dealsContainer {
  scroll-margin-top: 100px;
  margin-bottom: 20px;
}

.categoriesContainer {
  margin-bottom: 50px;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  background-color: var(--subBackgroundColor);
  border-radius: 25px;
  width: 60%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.leftToggleInternal {
  padding: 10px;
  margin: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 50%;
  color: var(--textColor);
  background-color: var(--subBackgroundColor);
}

.leftToggleActive {
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.rightToggleInternal {
  padding: 10px;
  margin: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 50%;
  color: var(--textColor);
  background-color: var(--subBackgroundColor);
}

.rightToggleActive {
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.menuHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 33%;
}

.selectedLocationContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.selectedLocationText {
  margin: 0;
  padding: 0;
  max-width: 85%;
}

.editLocationText {
  margin: 0;
  padding: 0;
  color: var(--buttonColor);
  cursor: pointer;
  text-decoration: underline;
  margin-left: 8px;
}

.createdBy {
  color: var(--subtextColor);
  font-weight: $semi-bold;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  margin-top: 10px;
}

.createdByLink {
  color: var(--buttonColor);
  cursor: pointer;
  text-decoration: underline;
}

.floatingCartButton {
  position: sticky;
  bottom: 50px;
  margin-left: auto;
  background-color: var(--buttonColor);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 80%);
  margin-right: 20px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.floatingCart {
  color: var(--buttonTextColor);
  width: 26px;
  height: 26px;
}

.floatingCheckoutButton {
  position: sticky;
  bottom: 50px;
  margin-left: auto;
  width: max-content;
  background-color: var(--buttonColor);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 80%);
  padding: 10px 15px;
  text-wrap: nowrap;
  border-radius: 15px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.floatingCheckoutText {
  margin: 0;
  padding: 0;
  color: var(--buttonTextColor);
  font-weight: $semi-bold;
  text-align: center;
  font-size: medium;
}

@media (width >= 875px) {
  .pointsBar {
    width: 50%;
  }

  .itemsGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .toggleContainer {
    width: 30%;
  }

  .customHomePageButton {
    width: 30%;
  }

  .headerRight {
    width: 35%;
    margin: 0;
  }

  .floatingCartButton {
    font-size: medium;
  }
}
