@use "src/common/styles/" as *;

.Receipt {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  max-width: 935px;
  overflow-x: none;
  min-height: calc(100vh - 160px);
}

.loading {
  margin-top: 10%;
}

.statusPill {
  margin-top: 15px;
  background-color: $dark-green;
  padding: 5px 25px;
  width: min-content;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cancelled {
  background-color: $red;
}

.pillText {
  margin: 0;
  color: $white;
  font-weight: $bold;
  text-align: center;
  text-wrap: nowrap;
}

.orderDetails {
  align-self: center;
  width: 100%;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  border: 1px solid;
  border-color: var(--buttonColor);
}

.orderItemsContainer {
  margin-top: 20px;
  width: 90%;
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additionalNotesContainer {
  width: 90%;
  align-self: center;
  padding-top: 10px;
  border-top: 1px solid var(--subBackgroundColor);
}

.additionalNotesLabel {
  margin: 0;
}

.additionalNotesText {
  margin: 0;
  color: var(--subtextColor);
  margin-top: 10px;
}

.priceDetails {
  border-top: 1px solid var(--subBackgroundColor);
  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 90%;
  align-self: center;
}

.priceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.priceRowText {
  margin: 0;
  font-weight: normal;
  color: var(--textColor);
}

.orderTotalText {
  margin: 0;
  font-size: large;
}

.orderDetailsInfo {
  background-color: var(--buttonColor);
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-sizing: border-box;
  padding: 20px 20px 50px;
}

.title {
  color: var(--buttonTextColor);
  margin: 0;
  margin-bottom: 15px;
}

.icon {
  color: var(--buttonTextColor);
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 10px;
}

.orderDetailInfoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.orderDetailsInfoName {
  color: var(--buttonTextColor);
  margin: 0;
  margin-right: 5px;
  font-size: medium;
}

.orderDetailsInfoSelection {
  padding: 0;
  margin: 0;
  color: var(--buttonTextColor);
  font-size: medium;
  font-weight: $bold;
  text-wrap: nowrap;
}

.promoCodeText {
  color: var(--buttonColor);
  font-weight: $bold;
}

@media (width >= 875px) {
  .orderDetails {
    width: 70%;
  }
}
