.Account {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 85%;
  max-width: 935px;
  margin-bottom: 25px;
}

.title {
  align-self: flex-start;
}

.accountItem {
  width: 100%;
}

.signOutButton {
  width: 100%;
  margin-top: 20px;
  border-width: 1px;
  padding: 10px 0;
}

.signOutText {
  margin: 0;
  color: var(--buttonColor);
}

@media (width >= 875px) {
  .accountItem {
    width: 70% !important;
  }

  .signOutButton {
    width: 70% !important;
  }
}
