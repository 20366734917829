@use "src/common/styles/" as *;

.OrderItem {
  width: 90%;
  border-bottom: 1px solid var(--subBackgroundColor);
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }
}

.refunded {
  opacity: 0.5;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.itemName {
  margin: 0;
  width: 50%;
}

.itemPrice {
  margin: 0;
  font-weight: normal;
}

.optionName {
  margin: 0;
  margin-top: 5px;
  color: var(--subtextColor);
  font-size: medium;
  width: 50%;
}

.optionPrice {
  margin: 0;
  margin-top: 5px;
  color: var(--subtextColor);
  font-size: medium;
  font-weight: normal;
}
