@use "src/common/styles/" as *;

.CheckoutOrderDetailsOptions {
  background-color: var(--buttonColor);
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-sizing: border-box;
  padding: 20px 20px 50px;
  border-color: var(--buttonColor);
}

.title {
  color: var(--buttonTextColor);
  margin: 0;
  margin-bottom: 15px;
}

.orderDetailOptionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  color: var(--buttonTextColor);
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 10px;
}

.orderDetailsOptionName {
  color: var(--buttonTextColor);
  margin: 0;
  margin-right: 5px;
  font-size: medium;
  font-weight: bold; 
}

.orderDetailsOptionSelection {
  padding: 0;
  margin: 0;
  color: var(--buttonTextColor);
  font-size: medium;
  word-wrap: break-word; 
  overflow-wrap: break-word;
  white-space: normal;
}

.checkoutDisclaimer {
  color: $white;
  margin: 0;
  padding: 0;
  font-size: small;
  font-style: italic;
  font-weight: $semi-bold;
  margin-top: 15px;
}

.platterName {
  font-weight: $bold;
  text-decoration: underline;
  cursor: pointer;
}