@use "src/common/styles/" as *;

.PersonalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 90%;
  margin-bottom: 25px;
  max-width: 650px;
}

.logo {
  margin-top: 50px;
  object-fit: contain;
  width: 200px;
  height: auto;
  margin-bottom: 15px;
}

.headingText {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
  width: 90%;
  font-size: large;
}

.input {
  width: 100%;
  margin-bottom: 25px;
}

.saveInfoButton {
  width: 100%;
  margin-bottom: 25px;
  margin-top: 25px;
}

.buttonText {
  margin: 10px 0;
}

.errorBox {
  width: calc(100% - 1px);
  margin-bottom: 25px;
  background-color: var(--cardColor);
  border-radius: 10px;
  border: var(--subBackgroundColor) 1px solid;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.errorBoxText {
  color: var(--subtextColor);
  font-weight: $bold;
  margin: 0;
  margin-left: 10px;
}

.errorIcon {
  color: var(--buttonColor);
}

@media (width >= 875px) {
  .PersonalInfo {
    width: 65%;
  }

  .headingText {
    font-size: xx-large;
    width: 75%;
  }
}
