@use "src/common/styles/" as *;

.SelectableButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--subBackgroundColor);
  color: var(--textColor);
  border-radius: 10px;
  width: 100%;
  padding: 10px 0;
  border: 2px solid var(--subBackgroundColor);
  font-weight: $bold;
  cursor: pointer;
}

.selected {
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
  border: 2px solid var(--buttonColor);
}
