@use "src/common/styles/" as *;

.OrderBanner {
  height: 80px;
  width: 100%;
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  border-bottom: var(--subBackgroundColor) 2px solid;
}

.orderBannerInner {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 0 20px;
  box-sizing: border-box;
  height: 100%;
}

.text {
  margin: 0;
  padding: 0;
  color: var(--textColor);
  font-size: medium;
}

.viewReceipt {
  color: var(--buttonColor);
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-top: 7.5px;
}

.statusContainer {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark-green;
  border-radius: 10px;
  text-wrap: nowrap;
  padding: 0 20px;
}

.cancelledContainer {
  background-color: $red;
  color:$white;
}

.statusText {
  color: $white;
  margin: 0;
  padding: 0;
  font-size: medium;
}

@media (width >= 875px) {
  .text {
    font-size: large;
  }

  .statusText {
    font-size: large;
  }

  .statusContainer {
    padding: 0 40px;
  }
}
