@use "src/common/styles/" as *;

.TextArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  margin: 0;
  font-size: medium;
}

.errorLabel {
  color: var(--subBackgroundColor);
}

.input {
  resize: none;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box;
  font-size: small;
  outline: none;
  font-family: var(--textFont);
  height: 75px;
  color: var(--textColor);
  background-color: var(--backgroundColor);
  border: var(--subBackgroundColor) 2px solid;

  &:focus {
    border: var(--subBackgroundColor) 2px solid;
  }
}

.maxLengthText {
  margin: 0;
  margin-top: 10px;
  font-size: small;
  color: var(--textColor);
  align-self: flex-end;
}

.labelErrorRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.errorMessage {
  margin: 0;
  color: var(--subtextColor);
  font-weight: normal;
  font-size: medium;
}

.errorInput {
  border: var(--subBackgroundColor) 2px solid;
}

@media (width >= 875px) {
  .label {
    font-size: larger;
  }

  .labelErrorRow {
    flex-direction: row;
  }
  
  .errorMessage {
    font-size: larger;
  }
}
