@use "src/common/styles/" as *;

.Pricing {
  padding-top: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.priceMainText {
  margin: 0;
  font-weight: $semi-bold;
}

.priceSubText {
  margin: 0;
  color: var(--subtextColor);
}

.strikeThroughContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.priceStrikeThrough {
  text-decoration: line-through;
  margin-left: 10px;
}
