@use "src/common/styles/" as *;

.CheckoutPriceDetails {
  border-top: 1px solid var(--cardColor);
  margin-top: 10px;
  padding-top: 20px;
  width: 90%;
  align-self: center;
}

.taxesAndFeesRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circleInfoIcon {
  color: var(--buttonColor);
  margin-left: 8px;
  cursor: pointer;
}

.priceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.priceRowText {
  margin: 0;
  font-weight: normal;
  color: var(--textColor);
}

.promoCodeText {
  color: var(--buttonColor);
  font-weight: $bold;
}

.orderTotalText {
  margin: 0;
  font-size: large;
}
