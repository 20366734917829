@use "src/common/styles/" as *;

.StatusBar {
  position: relative;
  width: 100%;
}

.linearGradient {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  height: 10px;
  width: 100%;
  background: linear-gradient(to right, $red, $light-green);
}

.primaryIndicator {
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
  background-color: var(--subBackgroundColor);
  border: 0.5px solid var(--cardColor);
  position: absolute;
  top: -3px;
}

.labelsConatiner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.label {
  font-weight: $semi-bold;
  text-align: center;
  font-size: small;
  margin: 0;
  width: 20%;
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}
