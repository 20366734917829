@use "src/common/styles/" as *;

.CateringInvoice {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  max-width: 935px;
  overflow-x: none;
  min-height: calc(100vh - 160px);
}

.loading {
  margin-top: 10%;
}

.container {
  width: 100%;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-self: center;
  margin-bottom: 35px;
}

.cateringDetailsContainer {
  background-color: var(--buttonColor);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 25px;
}

.disclaimerText {
  color: var(--buttonTextColor);
}


.bottomSection {
  background-color: var(--backgroundColor);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.titleText {
  color: var(--buttonTextColor);
  margin: 0;
  padding: 0;
}

.cateringDetailsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
}

.icon {
  color: var(--buttonTextColor);
  font-size: 17px;
  margin-right: 10px;
}

.successfullyPaid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15vh;
}

.successfullyPaidText {
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 25px;
  width: 300px;
}

.checkIcon {
  color: $dark-green;
  width: 50px;
  height: 50px;
}

.cateringInfoTitle {
  margin: 0;
  padding: 0;
  color: var(--buttonTextColor);
  margin-bottom: 5px;
  margin-right: 0;
}

.cateringInfoDetail {
  margin: 0;
  padding: 0;
  color: var(--buttonTextColor);
  font-weight: $regular;
}

.contentRow {
  display: flex;
  flex-direction: column;
}

.lineItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--subBackgroundColor);
  padding: 10px 0;
  width: 90%;
  align-self: center;
}

.lineItemTitle {
  font-weight: $bold;
  margin: 0;
  padding: 0;
}

.lineItemAmount {
  font-weight: $regular;
  margin: 0;
  padding: 0;
}

.receiptDetails {
  border-bottom: 1px solid var(--subBackgroundColor);
  padding: 10px;
  width: 90%;
  align-self: center;
}

.receiptDetailRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.taxesAndFeesRow {
  display: flex;
  align-items: center;
}

.receiptText {
  color: var(--subtextColor);
  font-weight: $semi-bold;
  margin: 0;
  padding: 0;
  padding: 5px 0;
}

.receiptBoldText {
  font-weight: $bold;
  margin: 0;
  padding: 0;
  padding: 5px 0;
}

.errorMessage {
  color: var(--subtextColor);
  margin: 0;
  padding: 0;
  width: 90%;
  align-self: center;
  text-align: center;
  font-size: medium;
  margin-bottom: 20px;
}

.loadingContainer {
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.circleInfoIcon {
  color: var(--buttonColor);
  margin-left: 8px;
  cursor: pointer;
}

.taxesAndFeesModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  justify-content: space-between;
  height: 100%;
  background-color: var(--cardColor);
  border-radius: 10px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modalTitle {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  color: var(--textColor);
}

.modalButton {
  width: 45%;
  background-color: var(--buttonColor);
  margin-bottom: 10px;
}

.modalButtonText {
  margin: 10px;
  color: var(--buttonTextColor);
}

.modalSection {
  border-bottom: var(--subBackgroundColor) 1px solid;
  width: 95%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  align-self: center;
}

.modalSectionRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.modalSectionBoldRow {
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  align-self: center;
}

.modalText {
  color: var(--textColor);
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
}

.modalSubtext {
  color: var(--subtextColor);
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-size: small;
  font-weight: $semi-bold;
}

.modalBoldText {
  color: var(--textColor);
  margin: 0;
  padding: 0;
  font-weight: $extra-bold;
}

@media (width >= 875px) {
  .container {
    width: 75%;
  }

  .cateringInfoTitle {
    margin-bottom: 0;
    margin-right: 5px;
  }

  .contentRow {
    flex-direction: row;
  }

  .successfullyPaidText {
    width: 500px;
  }
}
