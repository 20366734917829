@use "src/common/styles/" as *;

.CartDealCard {
  width: 100%;
}

.dealItemName {
  margin: 0;
  font-size: medium;
  margin-right: 10px;
  width: 60%;
  font-weight: normal;
}

.dealItemPrice {
  margin: 0;
  font-weight: normal;
  font-size: medium;
}

.strikeThroughPrice {
  text-decoration: line-through;
  margin-left: 5px;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.strikeThroughRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionName {
  color: var(--subtextColor);
  margin: 0;
  font-size: small;
}

.optionPrice {
  margin: 0;
  color: var(--textColor);
  font-size: small;
}
