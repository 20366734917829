@use "src/common/styles/" as *;

.SelectableCircle {
  min-height: 30px;
  min-width: 30px;
  border-radius: 15px;
  border: solid 2px var(--buttonColor);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}

.selected {
  background-color: var(--buttonColor);
  min-width: 22px;
  min-height: 22px;
  border-radius: 11px;
  box-sizing: border-box;
}
