@use "src/common/styles/" as *;

.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: var(--subBackgroundColor) 1px solid;
  align-self: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.footerTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerLeft {
  display: contents;
}

.footerCenter {
  display: contents;
}

.footerRight {
  display: contents;
}

.footerBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.footerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  width: 100%;
}

.footerHeading {
  margin: 0;
  text-align: center;
  margin-bottom: 15px;
  flex-direction: row;
}

.footerText {
  margin: 0;
  margin-bottom: 5px;
  line-height: 1.5;
  text-align: center;
}

.link {
  color: var(--buttonColor);
  font-weight: $semi-bold;
}

.storeImage {
  object-fit: contain;
  height: 50px;
  margin-bottom: 15px;
}

.storeImageContainer {
  display: flex;
  flex-direction: column;
}

.footerLogo {
  display: none;
}

.socialIconsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 80%;
  flex-wrap: wrap;
}

.socialMediaLink {
  width: 35px !important;
  height: 35px !important;
  margin: 0 10px;
  margin-bottom: 20px;
}

.footerSpan {
  display: inline;
}

.bold {
  display: inline;
  font-weight: $bold;
}

.underline {
  display: inline;
  text-decoration: underline;
}

@media (width >= 875px) {
  .Footer {
    max-width: 1150px;
  }

  .footerTop {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footerBottom {
    align-items: flex-start;
  }

  .footerLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
  }

  .footerCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
  }

  .footerRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 25%;
  }

  .footerSection {
    align-items: flex-start;
  }

  .footerHeading {
    text-align: left;
  }

  .footerText {
    text-align: left;
  }

  .footerLogo {
    display: block;
    width: 40%;
    align-self: flex-start;
    margin-top: 15px;
  }

  .socialIconsContainer {
    justify-content: flex-start;
  }

  .socialMediaLink {
    margin-right: 20px;
    margin-left: 0;
  }
}
