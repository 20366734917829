@use "src/common/styles/" as *;

.CartDeal {
  width: 100%;
  display: flex;
  align-items: stretch;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 10px;
  height: fit-content;
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dealImage {
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 30%;
}

.infoRows {
  width: 84%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.dealInfoContainer {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.titleText {
  margin: 0;
  font-size: medium;
  margin-bottom: 10px;
}

.dealPrice {
  margin: 0;
  font-weight: normal;
  font-size: medium;
}

.optionName {
  color: var(--subtextColor);
  margin: 0;
  font-size: small;
}

.optionPrice {
  margin: 0;
  color: var(--textColor);
  font-size: small;
}

.removeButton {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  color: var(--buttonColor) !important;
}

@media (width >= 875px) {
  .dealImage {
    max-width: 125px;
  }
}
