.Catering {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 85%;
  max-width: 935px;
  margin-bottom: 25px;
}

.itemsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px 20px;
  width: 100%;
}

.categoriesContainer {
  margin-bottom: 50px;
}

.categoryContainer {
  margin-bottom: 50px;
}

.sectionHeader {
  margin: 25px 0;
  margin-bottom: 35px;
}

.addressAutocomplete {
  width: 100%;
  position: relative;
}

.addressLoading {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: var(--subtextColor) 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none;
  padding: 10px 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  background-color: var(--cardColor);
  margin-top: -35px;
}

.addressPredictions {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: var(--subBackgroundColor) 2px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: none;
  padding: 10px 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  background-color: var(--cardColor);
  margin-top: -35px;
}

.addressPredictionButton {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  background-color: var(--cardColor);
  text-align: left;
  cursor: pointer;
  border-bottom: var(--subBackgroundColor) 2px solid;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.addressPredictionText {
  margin: 0;
  padding: 0;
  color: var(--textColor) !important;
}

.noBorderRadius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input {
  width: 100%;
  margin-bottom: 35px;
}

.submitInfoButton {
  width: 100%;
  margin-bottom: 25px;
  margin-top: 25px;
}

.buttonText {
  margin: 10px 0;
  color: var(--buttonTextColor);
  background-color: var(--buttonColor);
}

.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (width >= 875px) {
  .categoriesContainer {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .itemsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
