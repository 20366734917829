@use "src/common/styles/" as *;

.tipButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 15px;
}

.tipButton {
  width: 20%;
  height: 35px;
  color: var(--textColor);
  border: 1px solid var(--buttonColor);
  background-color: transparent;
  cursor: pointer;

  &.selected {
    background-color: var(--buttonColor);
    color: var(--buttonTextColor);
  }
}

.customTipInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.customTipLabel {
  margin: 0;
  font-weight: normal;
  color: var(--textColor);
  margin-bottom: 5px;
}

.customTipInput {
  width: 25%;
  margin-bottom: 20px;
  background-color: var(--cardColor);
}

.errorMessage {
  margin-top: -15px;
  font-weight: normal;
  color: var(--buttonColor);
}
