/* Colors used throughout the website */
$light-green: #1dc31a;
$dark-green: #2ca622;
$red: #a62222;
$white: #fff;

:export {
  light-green: $light-green;
  dark-green: $dark-green;
  red: $red;
  white: $white;
}
