@use "src/common/styles/" as *;

.NonOwnerManagedHeader {
  align-self: center;
  width: 90%;
  max-width: 935px;
  margin-bottom: 25px;
}

.platterLogo {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.lastUpdatedText {
  color: var(--subtextColor);
  margin: 0;
  padding: 0;
  font-size: smaller;
  margin-bottom: 10px;
}

.informationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  background-color: #eee;
  border-radius: 10px;
  margin-bottom: 20px;
}

.leftRestaurantInfo {
  width: 100%;
  margin-bottom: 20px;
}

.rightRestaurantInfo {
  margin-right: 5%;
}

.nameReviewRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.restaurantNameText {
  margin: 0;
  padding: 0;
  margin-right: 15px;
  font-weight: $semi-bold;
}

.aboutUs {
  margin: 0;
  padding: 0;
  font-size: smaller;
  margin-top: 10px;
}

.restaurantInfoSectionTitle {
  margin: 0;
  padding: 0;
  font-weight: $bold;
  margin-bottom: 5px;
  font-size: small;
}

.phoneNumber {
  margin: 0;
  padding: 0;
  font-size: small;
  cursor: pointer;
  transition: opacity 0.2s;
  text-decoration: underline;
  margin-bottom: 30px;
  color: var(--buttonColor);

  &:hover {
    opacity: 0.5;
  }
}

.locationLine1 {
  margin: 0;
  padding: 0;
  font-size: small;
  margin-bottom: 5px;
}

.locationCityStateZip {
  margin: 0;
  padding: 0;
  font-size: small;
}

.headerLinks {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #eee;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.headerLink {
  padding: 0;
  margin: 0 10px;
  cursor: pointer;
  transition: opacity 0.2s;
  color: black;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
}

.activeLink {
  color: var(--buttonColor);
  text-decoration: underline;
}


@media (width >= 875px) {
  .informationContainer {
    flex-direction: row;
    justify-content: space-between;
  }

  .leftRestaurantInfo {
    width: 60%;
    margin-bottom: 0;
  }

  .headerLink {
    margin: 0 25px;
  }
}
