@use "src/common/styles/" as *;

.DealPicker {
  width: 100%;
  margin-top: 10px;
  padding: 20px 0;
}

.itemNameIncludedContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.includedContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--buttonColor);
  border-radius: 20px;
  padding: 5px 15px;
}

.includedText {
  color: var(--buttonTextColor);
  margin: 0;
  padding: 0;
  font-weight: $semi-bold;
}

.itemPicker {
  margin-top: 20px;
  padding-bottom: 30px;
}

.optionPicker {
  margin-left: 20px;
  width: 90% !important;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.optionsContainer {
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.itemDescription {
  margin: 10px 0;
  color: var(--subtextColor);
}