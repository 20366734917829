@use "src/common/styles/" as *;

.CheckoutOrderDetails {
  width: 100%;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: var(--buttonColor);
}

.cartItemsContainer {
  margin-top: 20px;
  width: 90%;
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cartItem {
  width: 90%;
  border-bottom: 1px solid var(--cardColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }
}

.cartItemName {
  margin: 0;
  width: 50%;
}

.cartItemPrice {
  margin: 0;
  font-weight: normal;
}

.additionalNotesContainer {
  width: 90%;
  align-self: center;
  padding-top: 10px;
}

.additionalNotesLabel {
  margin: 0;
}

.additionalNotesText {
  margin: 0;
  color: var(--textColor);
  margin-top: 10px;
}

.loadingContainer {
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.payButton {
  width: 90%;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.payText {
  margin: 0;
  color: var(--buttonTextColor);
}

.errorMessage {
  color: var(--subtextColor);
  margin: 0;
  padding: 0;
  width: 90%;
  align-self: center;
  text-align: center;
  font-size: medium;
  margin-bottom: 20px;
}
