@use "src/common/styles/" as *;

.TextInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--textColor);
}

.label {
  margin: 0;
  font-size: medium;
  margin-bottom: 8px;
}

.errorLabel {
  color: var(--textColor);
}

.inputRow {
  display: flex;
  align-items: center;
  width: 100%;
}

.inputPrefix {
  margin: 0;
  padding: 0;
  margin-right: 5px;
}

.input {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  font-size: medium;
  outline: none;
  background-color: var(--backgroundColor);
  color: var(--textColor);
  border: var(--subBackgroundColor) 2px solid;
  font-family: var(--fontFamily);

  &:focus {
    border: var(--buttonColor) 2px solid;
  }
}

.errorMessage {
  margin: 0;
  color: var(--errorColor);
  font-weight: normal;
  font-size: medium;
  margin-top: 8px;
}

.errorInput {
  border: var(--subBackgroundColor) 2px solid;
}

@media (width >= 875px) {
  .label {
    font-size: larger;
  }
  
  .errorMessage {
    font-size: larger;
  }
}
