@use "src/common/styles/" as *;

.OrderDetails {
  width: 100%;
  box-shadow: 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: var(--buttonColor);
}

.cartItemsContainer {
  margin-top: 20px;
  width: 90%;
  align-self: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--cardColor);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additionalNotesContainer {
  width: 90%;
  align-self: center;
  padding: 20px 0;
}

.borderBottom {
  border-bottom: 1px solid var(--subtextColor);
}

.rewardsSelector {
  align-self: center;
  width: 90% !important;
  margin-top: 20px;
}

.priceContainer {
  border-top: 1px solid var(--cardColor);
  margin-top: 20px;
  width: 90%;
  align-self: center;
}

.priceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.priceRowText {
  margin: 0;
  font-weight: normal;
  color: var(--textColor);
}

.promoCodeText {
  color: var(--buttonColor);
  font-weight: $bold;
}

.orderTotalText {
  margin: 0;
}

.loadingContainer {
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkoutButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.loginToEarnRewardsButton {
  margin-bottom: 0;
}

.buttonText {
  margin: 0;
  color: var(--buttonTextColor);
}

.youWouldEarnText {
  margin: 0;
  color: var(--buttonTextColor);
  font-style: italic;
  margin-top: 5px;
}

.checkoutAsGuestText {
  margin: 0;
  color: var(--buttonColor);
}

.tipRowText {
  margin: 0;
  font-weight: $semi-bold;
  color: var(--textColor);
}

.errorText {
  align-self: center;
  margin-top: 5px;
  font-weight: normal;
  color: $red;
}

.invalidOrderItemsText {
  align-self: center;
  margin-top: 5px;
  font-weight: normal;
  color: $red;
  text-align: center;
}
