@use "src/common/styles/" as *;

.Deal {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 80%;
  margin-bottom: 25px;
}

.DealNotFound {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin-bottom: 25px;
  height: 70vh;
}

.backToMenuButton {
  margin-top: 10px;
  width: 50%;
}

.mainMenuText {
  margin: 5px;
}

.errorMessage {
  text-align: center;
  width: 80%;
  font-size: large;
}

.loading {
  height: 80vh;
  display: flex;
  justify-content: center;
}

.linksContainer {
  display: none;
}

.link {
  color: var(--buttonColor);
  font-weight: $bold;
  font-size: small;
}

.blackLink {
  color: var(--textColor);
}

.linkSpacer {
  margin: 0 10px;
}

.dealContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.dealDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dealImageContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

.dealName {
  margin: 0;
  margin-top: 10px;
  font-size: larger;
}

.logoImage {
  object-fit: contain;
}

.addToCartBanner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--subBackgroundColor);
  box-sizing: border-box;
  padding: 10px 5vw;
  z-index: 10;
}

.addToCartButton {
  width: 50%;
  border-color: var(--buttonColor);

  &:disabled {
    border-color: var(--buttonDisabledColor);
  }
}

.addToCartLoading {
  width: 50%;
  display: flex;
  justify-content: center;
}

.addToCartDealName {
  width: 45%;
  font-size: medium;
}

.addToCartButtonText {
  text-wrap: nowrap;
  font-size: small;
  color: var(--buttonTextColor);

  .addToCartButton:disabled & {
    color: var(--buttonDisabledColor);
  }
}

@media (width >= 875px) {
  .Deal {
    width: 80%;
  }

  .linksContainer {
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 0;
  }

  .link {
    font-size: medium;
  }

  .dealContainer {
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-top: 0;
  }

  .dealDetailsContainer {
    width: 50%;
    min-height: 400px;
  }

  .dealImageContainer {
    width: 50%;
    justify-content: flex-end;
  }

  .image {
    width: 80%;
  }

  .dealName {
    margin: 0;
    font-size: larger;
  }

  .addToCartDealName {
    font-size: larger;
  }

  .addToCartLoading {
    width: 35%;
  }
}
