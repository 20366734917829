@use "src/common/styles/" as *;

.Terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  width: 90%;
  margin-bottom: 50px;
}

.lastUpdated {
  color: var(--textColor);
}

@media (width >= 875px) {
  .Terms {
    width: 65%;
    max-width: 1150px;
  }
}
