@use "src/common/styles/" as *;

.OrderDetailsOptions {
  background-color: var(--buttonColor);
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-sizing: border-box;
  padding: 20px 20px 100px;
  border-color: var(--buttonColor);
}

.title {
  color: var(--buttonTextColor);
  margin: 0;
  margin-bottom: 15px;
}

.orderDetailOptionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  color: var(--buttonTextColor);
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 10px;
}

.orderDetailsOptionName {
  color: var(--buttonTextColor);
  margin: 0;
  margin-right: 5px;
  font-size: medium;
}

.restaurantClosedText {
  color: var(--buttonTextColor);
  margin: 0;
  font-style: italic;
  font-size: medium;
  font-weight: $bold;
}

.orderDetailsOptionButton {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  color: var(--buttonTextColor);
  cursor: pointer;
  text-decoration: underline;
  font-size: medium;
  font-weight: $bold;
  text-align: left;
}

.orderDetailsOptionText {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  color: var(--buttonTextColor);
  font-size: medium;
  font-weight: $bold;
  text-align: left;
}

.orderTypeModalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-color: var(--cardColor);
  border: none;
  margin: 0;
  box-shadow: none;
}

.orderPickupTimeModalContainer {
  height: 300px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.addressModalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--cardColor);
}

.selectableButton {
  width: 90%;
  margin-bottom: 25px;
}

.italicText {
  font-style: italic;
}

.enterAddressText {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.noticeInstructions {
  font-size: medium;
  color: var(--subtextColor);
  margin: 0;
}

.addressInput {
  width: 100%;
  margin-top: 15px;
}

.deliveryInstructionsInput {
  width: 100%;
  margin-top: 25px;
}

.addressAutocomplete {
  width: 100%;
  position: relative;
}

.addressLoading {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: var(--subtextColor) 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none;
  padding: 10px 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  background-color: var(--cardColor);
}

.addressPredictions {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: var(--subBackgroundColor) 2px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: none;
  padding: 10px 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  background-color: var(--cardColor);
}

.addressPredictionButton {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  background-color: var(--cardColor);
  text-align: left;
  cursor: pointer;
  border-bottom: var(--subBackgroundColor) 2px solid;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.addressPredictionText {
  margin: 0;
  padding: 0;
  color: var(--textColor) !important;
}

.noBorderRadius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.contactlessDeliveryRow {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
}

.contactlessDeliveryText {
  margin: 0;
  margin-left: 10px;
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.buttonsContainer {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  align-items: center;
  margin-top: 50px;
}

.saveButton {
  width: 45%;
  color: var(--buttonTextColor);
  border-color: var(--buttonColor);
}

.cancelButton {
  width: 45%;
  border-color: var(--subtextColor);
}

.buttonText {
  margin: 10px;
}

@media (width >= 875px) {
  .buttonsContainer {
    width: 50%;
  }
}
