@use "src/common/styles/" as *;

.NonOwnerManagedFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: var(--subBackgroundColor);
  z-index: 1000;
  padding: 15px 5vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.orderOnlineText {
  font-size: medium;
  font-weight: $bold;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}

.image {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  border-radius: 12.5px;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}

.getDirectionsButton {
  width: min-content;
  padding: 15px 20px;
  border-color: var(--buttonColor);
  color: var(--buttonTextColor);
  font-size: medium;
  align-self: flex-end;

  h3 {
    transition: transform 0.3s;
  }

  &:hover {
    h3 {
      transform: scale(1.05);
    }
  }
}

.getDirectionsButtonText {
  margin: 0;
  padding: 0;
  text-wrap: nowrap;
  white-space: nowrap;
  font-size: medium;
}
