@use "src/common/styles/" as *;

.title {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  align-self: flex-start;
  margin-left: 5%;
}

.modal {
  height: 300px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: var(--cardColor);
}

.selectableButton {
  width: 90%;
  margin-bottom: 25px;
}
